// src/pages/ChatPage.jsx
import React, { useEffect, useState } from 'react';
import { jwtDecode} from 'jwt-decode';
import { SendBirdProvider, ChannelList, Channel, ChannelSettings } from '@sendbird/uikit-react';
import '@sendbird/uikit-react/dist/index.css';
import { initializeSendBirdUser } from '../Services/sendbirdInit';

const ChatPage = () => {
  const [userId, setUserId] = useState('');
  const [channelUrl, setChannelUrl] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentUserId = decodedToken.id; // Extract `id` or appropriate key from token
        const currentUserName = decodedToken.name || 'Me'; // Provide a fallback if name is not in token

        initializeSendBirdUser(currentUserId+'', currentUserName+'');
        setUserId(currentUserId+'');
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    } else {
      console.error('No token found. User not authenticated.');
    }
  }, []);

  return (
    userId && (
      <SendBirdProvider appId="9FD0F563-359A-4D98-8EE9-A2B548706773" userId={''+userId}   config={{
        disableCreateChannel: true,  // Completely remove the "Create Channel" button
      }}>
        <div style={{ display: 'flex', height: '100vh' }}>
          <div style={{ width: '30%' }}>
            <ChannelList onChannelSelect={(channel) => setChannelUrl(channel?.url)} />
          </div>
          <div style={{ width: '70%' }}>
            {channelUrl ? (
              <Channel channelUrl={channelUrl} />
            ) : (
              <div>Select a channel to start chatting</div>
            )}
          </div>
        </div>
      {/*   {channelUrl && <ChannelSettings channelUrl={channelUrl} />}*/}
      </SendBirdProvider>
    )
  );
};

export default ChatPage;
