// sendbirdInit.js
import SendBird from 'sendbird';

const sb = new SendBird({ appId: '9FD0F563-359A-4D98-8EE9-A2B548706773' });  // Replace with your actual SendBird App ID

export const initializeSendBirdUser = (userId,nickname,profileImageUrl) => {
    // Ensure userId is a string
    const userIdStr = userId.toString();
    console.log('Connecting user with ID:', userIdStr);
  
    // Attempt to connect with SendBird
    sb.connect(userIdStr, (user, error) => {
      if (error) {
        console.error('SendBird connection failed:', error);
        return;
      }
  
      // Retrieve and log the nickname after a successful connection
      
      console.log('Connected with Nickname:', nickname);
  
      // Optionally update user info
      sb.updateCurrentUserInfo(nickname, profileImageUrl, (response, error) => {
        if (error) {
          console.error('Failed to update user info:', error);
        } else {
          console.log('User info updated successfully:', response);
        }
      });
    });
  };
  