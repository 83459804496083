import React from 'react';
import Home from './Home'; // Import Home if it's being used

const Contact = () => {
  return (
    <div>
      <h1>Contact Page</h1>
      {/* Check if you're trying to reference Home here */}
      {/* <Home /> or any similar references */}
    </div>
  );
};

export default Contact;
